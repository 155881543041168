var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters["permissions/checkIfUserHasPermission"](
    "permissions.read-list"
  )
    ? _c("div", [
        _c("div", { staticClass: "batchSelect" }, [
          _c("h3", { staticClass: "batchSelectTitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("batchSelect")) + " ")
          ]),
          _c(
            "select",
            {
              staticClass: "form-control",
              domProps: { value: _vm.predefinedRole },
              on: { input: _vm.selectRole }
            },
            [
              _c(
                "option",
                { attrs: { value: "", selected: "", disabled: "" } },
                [_vm._v(" " + _vm._s(_vm.$t("select")) + " ")]
              ),
              _c("option", { attrs: { value: "all" } }, [
                _vm._v(" " + _vm._s(_vm.$t("all")) + " ")
              ]),
              _c("option", { attrs: { value: "none" } }, [
                _vm._v(" " + _vm._s(_vm.$t("none")) + " ")
              ]),
              _vm._l(_vm.roles, function(role) {
                return _c(
                  "option",
                  {
                    key: "role" + role.id + role.name,
                    domProps: { value: role.id }
                  },
                  [_vm._v(" " + _vm._s(role.name) + " ")]
                )
              })
            ],
            2
          )
        ]),
        _c("h3", [_vm._v(_vm._s(_vm.$tc("permission", 2)))]),
        _c(
          "ul",
          { staticClass: "permissionsList" },
          _vm._l(_vm.permissions, function(permission) {
            return _c(
              "li",
              {
                key: permission.name + permission.id,
                staticClass: "permission"
              },
              [
                _c("input", {
                  staticClass: "permissionInput",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: _vm.checkIfPermissionIsActive(permission.name)
                  },
                  on: {
                    input: function($event) {
                      return _vm.togglePermissionStatus($event, permission)
                    }
                  }
                }),
                _c("label", [_vm._v(_vm._s(permission.name))])
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }